import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { ReduxWrapper } from '@/components/common/ReduxWrapper';
import store from '@/store';
import dynamic from 'next/dynamic';
import useIsMobile from '@/hooks/useMediaQuery';
const HeaderMobile = dynamic(() => import('./components/mobile/HeaderMobile'));
const FooterMobile = dynamic(() => import('./components/mobile/footer-mobile'));
const ScrollToTopButton = dynamic(
  () => import('@/components/common/ScrollTop')
);
const RequestCall = dynamic(() => import('@/components/common/request-call'));
const BuyOnPhone = dynamic(() => import('@/components/common/buy-on-phone'));
const WhatsappCall = dynamic(() => import('@/components/home/whatsapp-call'));

// import Zendesk from '@/components/common/Zendesk';
import { ZendeskProvider } from 'react-use-zendesk';
import { GoogleOAuthProvider } from '@react-oauth/google';
const Header = dynamic(() => import('./components/Header'));
const Footer = dynamic(() => import('./components/Footer'));
const DiscountStrip = dynamic(() => import('./components/DiscountStrip'));
const FooterTitleMobile = dynamic(
  () => import('./components/mobile/footer-title-link-mobile')
);

const LoginModal = dynamic(() => import('./components/login-modal'), {
  ssr: false,
});

type Props = {
  children: ReactNode & {
    props?: any;
  };
  data?: any;
  isMobile?: boolean;
};

const Layout = ({ children, data = {} }: Props) => {
  const { infoData = {}, error = null } = data || {};

  // const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const isMobile = useIsMobile();
  // const isMobile = false;

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_ID as string}>
      <ZendeskProvider apiKey={process.env.NEXT_PUBLIC_ZENDESK_API_KEY || ''}>
        <Provider store={store}>
          <ReduxWrapper>
            <div className="common-wrapper">
              <LoginModal isMobile={isMobile} />
              {isMobile ? (
                <>
                  <HeaderMobile />
                  <WhatsappCall />
                  {/* <Zendesk /> */}
                  <RequestCall />
                </>
              ) : (
                <>
                  <DiscountStrip />
                  <Header />
                  <ScrollToTopButton />
                  {/* <Zendesk /> */}
                  <RequestCall />
                  <BuyOnPhone />
                </>
              )}

              <main>{children}</main>
              {isMobile ? (
                <>
                  <FooterMobile />
                  <FooterTitleMobile
                    informationData={infoData?.data}
                    error={error}
                  />
                </>
              ) : (
                <Footer />
              )}
            </div>
          </ReduxWrapper>
        </Provider>
      </ZendeskProvider>
    </GoogleOAuthProvider>
  );
};

export default Layout;
